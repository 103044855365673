import Layout from "../common/layout"
import DetailMedia from "../company/DetailMedia/DetailMedia"
import Head from "../common/Head"
import { useFormatter } from "helpers/i18n"
import img from "images/Company/Media/Meta.png"

const PressRelease = () => {
  const { __ } = useFormatter()
  return (
    <>
      <Head
        showCaptcha={false}
        title={__({ defaultMessage: "Press Release" })}
        description={__({
          defaultMessage:
            "OnPoint closes the largest Series B round for a SEA e-commerce enabler led by SeaTown Holdings International",
        })}
        image={img}
      />
      <Layout>
        <DetailMedia />
      </Layout>
    </>
  )
}

export default PressRelease
